<template>
    <div class="fixed w-full flex justify-center z-50 transition duration-700 bottom-0" id="bottom-navigation" >
        <div class="shadow bg-white  rounded-t-lg w-full sm:w-[600px]"  style="box-shadow: 0 -4px 8px 0 rgb(0 0 0 / 7%)">
            
            <app-bottom-services ref="bottomServices"></app-bottom-services>
            <article-notification-orders></article-notification-orders>
            <app-bottom-cart></app-bottom-cart>
            <app-bottom-online-checkin></app-bottom-online-checkin>

            <app-bottom-privacy-cookie v-if="$route.name !== 'locations-occupancy'"></app-bottom-privacy-cookie>
            <div class="relative p-3 safe-padding-b-3" :class="[$root.hotelCode == 'taigara' ? 'bg-[#455E4C] rounded-t-lg text-white' : '']">
                <app-cart-button @click="openCart" v-if="$root.cart && $root.cart.products.length && !($route.name == 'home' || $route.name == '')"></app-cart-button>
                <div class="flex px-3 md:px-6" :class="[navigationMenu && navigationMenu.length < 3 ? 'justify-center space-x-20' : 'justify-between']" v-else>
                    <!-- home button -->
                    <button  v-for="item in navigationMenu" v-if="item.active" @click="clickMenu(item)" class="flex items-center flex-col text-center min-w-[55px] relative" :class="[$root.hotelCode == 'taigara' ? 'text-[#ffffff]' : $root.hotelCode == 'wyndhamsroclawoldtown' ? 'text-[#0574BC]' : 'text-[#7e7e7e]']">
                      <svg v-if="item.icon == 'home'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fill-current w-6 h-6"><path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z"></path><path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z"></path></svg>                        
                        <svg v-if="item.icon == 'services'" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" class="fill-current"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M16 6v8h3v8h2V2c-2.76 0-5 2.24-5 4zm-5 3H9V2H7v7H5V2H3v7c0 2.21 1.79 4 4 4v9h2v-9c2.21 0 4-1.79 4-4V2h-2v7z"></path></svg>
                        <svg v-if="item.icon == 'chat'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="fill-current w-6 h-6"><path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z"></path><path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z"></path></svg>
                        <svg v-if="item.icon == 'help'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fill-current base-color w-7 h-7" style="margin-top: -4px"><path d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z"></path></svg>
                        <svg v-if="item.icon == 'orders'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6"><path fill-rule="evenodd" d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z" clip-rule="evenodd"></path></svg>
                        <svg v-if="item.icon == 'cart'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6"><path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"></path></svg>
                        <svg v-if="item.icon == 'spa'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" class="w-6 h-6"><title>Leaf</title><path d="M161.35 242a16 16 0 0122.62-.68c73.63 69.36 147.51 111.56 234.45 133.07 11.73-32 12.77-67.22 2.64-101.58-13.44-45.59-44.74-85.31-90.49-114.86-40.84-26.38-81.66-33.25-121.15-39.89-49.82-8.38-96.88-16.3-141.79-63.85-5-5.26-11.81-7.37-18.32-5.66-7.44 2-12.43 7.88-14.82 17.6-5.6 22.75-2 86.51 13.75 153.82 25.29 108.14 65.65 162.86 95.06 189.73 38 34.69 87.62 53.9 136.93 53.9a186 186 0 0027.77-2.04c41.71-6.32 76.43-27.27 96-57.75-89.49-23.28-165.94-67.55-242-139.16a16 16 0 01-.65-22.65zM467.43 384.19c-16.83-2.59-33.13-5.84-49-9.77a157.71 157.71 0 01-12.13 25.68c-.73 1.25-1.5 2.49-2.29 3.71a584.21 584.21 0 0058.56 12 16 16 0 104.87-31.62z"/></svg>
                        <svg v-if="item.icon == 'info'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6"><path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" /></svg>
                        <svg v-if="item.icon == 'phone_call'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6"><path fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clip-rule="evenodd" /></svg>                        

                        <div class="inline-flex absolute -top-1 right-0 justify-center items-center w-4 h-4 text-[10px] font-bold text-white bg-red-500 rounded-full border-2 border-white" v-if="item.code == 'chat' && badges.chat > 0"></div>
                        <div class="inline-flex absolute -top-1 right-0 justify-center items-center w-4 h-4 text-[10px] font-bold text-white bg-red-500 rounded-full border-2 border-white" v-if="item.code == 'orders' && badges.order > 0" ></div>
                        <div class="inline-flex absolute -top-1 right-0 justify-center items-center w-4 h-4 text-[10px] font-bold text-white bg-red-500 rounded-full border-2 border-white" v-if="item.code == 'cart' && $root.cart && $root.cart.products.length"></div>
                        
                        <div class="text-[10px]" :class="[item.icon == 'help' ? 'base-color' : '']">{{ $root.getLangText(item.title) }}</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppBottomCart from './AppBottomCart';
import AppBottomServices from './AppBottomServices';
import ArticleNotificationOrders from './ArticleNotificationOrders';
import AppCartButton from '../../../components/article/AppCartButton.vue';
import AppBottomOnlineCheckin from './AppBottomOnlineCheckin.vue';
import AppBottomPrivacyCookie from './AppBottomPrivacyCookie.vue';
import { eventBus } from '../../../main';

export default {
    name: 'AppBottomNavigation',

    props: ["navigationMenu"],

    components: { AppBottomServices, ArticleNotificationOrders, AppCartButton, AppBottomCart, AppBottomOnlineCheckin, AppBottomPrivacyCookie },

    data() {
        return {
            visibleBottomServices: false,
            visibleBottomMyOrders: false,
            visibleBottomCart: false,

            badges: {
                chat: (localStorage.getItem('count-unread-messages') != undefined) 
                    ? parseInt(localStorage.getItem('count-unread-messages')) 
                    : 0,
                order: 0,
            },
        }
    },

    mounted() {
        eventBus.$emit('navigation-menu-uploaded');

        if (this.$route.name !== 'home') {
            document.getElementById('bottom-navigation').classList.add('bottom-0');
            document.getElementById('bottom-navigation').classList.remove('bottom-[-100px]')
        }
    },

    created() {
        eventBus.$on('badges-bottom-navigation', data => {
            if (data.action == 'chat') {
                localStorage.setItem('count-unread-messages', data.count);
                this.badges.chat = data.count;
            }
        });

        eventBus.$on('active-bottom-navigation', data => {
            if (data.menu == 'my-orders')
                this.visibleBottomMyOrders = data.visible;

            if (data.visible === true)
                this.badges.order = 0;
        });
        
        eventBus.$on('badges-order-bottom-navigation', data => {
            if (data.menu == 'my-orders')
                this.badges.order = data.count;
        });
    },

    watch: {
        visibleBottomServices(val) {
            this.$root.freezeActivePageScroll(val);
        }
    },
    
    methods: {
        clickMenu(item) {
            if (item.external_link) {
                window.location = item.external_link;
            }

            if (item.code == 'home') {
                this.$router.push('/');
            }

            if (item.code == 'chat') {
                eventBus.$emit('open-chat');
            }

            if (item.code == 'services') {
                this.visibleBottomServices = !this.visibleBottomServices;  
                this.$refs.bottomServices.fetchSections();
            }

            if (item.code == 'orders') {
                eventBus.$emit('toggle-my-orders');
            }

            if (item.code == 'cart') {
                if (Object.keys(this.$root.cart).length > 0 && this.$root.cart.products.length)  {
                    eventBus.$emit('cart');
                    return;
                }
                
                this.visibleBottomCart = !this.visibleBottomCart;
            }
        },
    }
}
</script>